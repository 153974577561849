import {
    InputAdornment,
    styled,
    TextField,
} from "@mui/material";
import SearchIcon from '@mui/icons-material/Search';
import { Dispatch, SetStateAction, useEffect } from "react";
import UseDebonce from "./UseDebonce";

const CSSSearchField = styled(TextField)({
    backgroundColor: "#f1f1f1",
    border: "none",
    borderRadius: "30px",
    "& .MuiInputBase-root": {
        color: "#173456",
        "& input": {
            textIndent: "10px",
        },
    },
    '& .MuiOutlinedInput-root': {
        '& fieldset': {
            color: '#f1f1f1',
            borderColor: '#f1f1f1',
            borderRadius: "30px"
        },
        '&:hover fieldset': {
            color: '#f1f1f1',
            borderColor: '#f1f1f1',
            borderRadius: "30px"
        },
        '&.Mui-focused fieldset': {
            color: '#f1f1f1',
            borderColor: '#f1f1f1',
            borderRadius: "30px"
        },
    },
});

interface PropsInterface {
    text: string | null
    setText: Dispatch<SetStateAction<string>>
    size?: "small" | "medium" | undefined
    placeholder?: string | undefined

    //Parametros del buscador
    useFilters: boolean
    data: any []
    result: any []
    empty: boolean
    itemSelected?: any | undefined
    setResult: Dispatch<SetStateAction<any []>>
    setEmpty: Dispatch<SetStateAction<boolean>>
    setPagination: Dispatch<SetStateAction<number>>
    fullWidth?: boolean
    filter?: string
}
const SearchField = ( props: PropsInterface ) => {
    const deb = UseDebonce(props.text, 100)
    const information = props.data
    const { data, empty, setResult, setEmpty, itemSelected, useFilters, filter, setPagination } = props

    useEffect(()=>{
        if ( data.length > 0 ) {
            let filtered = data.filter((e: any) => e.label.toLowerCase().includes(deb.toLowerCase()))
            setResult(filtered)
            setEmpty(true)
            setPagination(1)
            

            if ( !empty ){
                setResult(data)
            } else {
                setResult(filtered)
                setEmpty(true)
            }
            
            if ( props.text === "" ){
                setResult(data)
                setEmpty(false)
                return
            } 

            if ( useFilters && filter !== undefined && filter !== "" ) {
                if (itemSelected.label !== "" && itemSelected.label !== filter) {
                if ( props.text !== "" && !empty){
                        return
                    } 
                    let filtered2 = information.filter((e: any) => e.U_canal.toLowerCase().includes(itemSelected.label.toLowerCase()))
                    let filtered3 = filtered2.filter((e: any) => e.label.toLowerCase().includes(deb.toLowerCase()))

                    setResult(filtered3)
                }
            }
        }
    // eslint-disable-next-line
    }, [deb])

    return (
        <CSSSearchField 
            InputProps={{
                endAdornment: <InputAdornment position="start"><SearchIcon style={{ color: "#666666" }} /></InputAdornment>,
            }}
            placeholder={props.placeholder}
            value={props.text}
            onChange={(e: any) => { props.setText(e.target.value) }}
            size={ props.size } 
            fullWidth={ props.fullWidth }
            sx={{
                "& .MuiInputBase-root": {
                    "& input": {
                        color: props.text !== "" && props.text !== null ? "" : "#666666",
                        fontWeight: props.text !== "" && props.text !== null ? 200 : 600
                    },
                },
            }}
        />
    )
}

export default SearchField