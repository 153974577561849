import { initializeApp } from "firebase/app";
// import { getDatabase, ref, onValue, set } from 'firebase/database';
import { getFirestore } from "firebase/firestore";
// eslint-disable-next-line 
import { collection, query, where, onSnapshot,  doc, getDoc  } from "firebase/firestore";


//Documentacion
//https://modularfirebase.web.app/common-use-cases/firestore/

const firebaseConfig = {
    apiKey: `${ process.env.REACT_APP_API_KEY }`,
    authDomain:`${ process.env.REACT_APP_AUTH_DOMAIN }`,
    databaseURL: `${ process.env.REACT_APP_DATABASE_URL }`,
    projectId: `${ process.env.REACT_APP_PROJECT_ID }`,
    storageBucket: `${ process.env.REACT_APP_STORAGE_BUCKET }`,
    messagingSenderId: `${ process.env.REACT_APP_MESSAGING_SENDER_ID }`,
    appId: `${ process.env.REACT_APP_APP_ID }`,
    measurementId: `${ process.env.REACT_APP_MEASUREMENT_ID }`,
};

export const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const getCollection = async (nameCollection: string) => {
    // const q = query(collection(db, nameCollection));
    // const unsubscribe = onSnapshot(q, (querySnapshot) => {
    //     const data: any[] = [];
    //     querySnapshot.forEach((doc) => {
    //         data.push(doc.data());
    //     });
    //     return data;
    // });
};


export const getDocument = async (nameCollection:string, nameDocument:string) =>{
    const docRef = doc(db, nameCollection, nameDocument);
    const docSnap = await getDoc(docRef);
    if (docSnap.exists()) {
        return docSnap.data()
      } else {
        // doc.data() will be undefined in this case
        return undefined;
    }
}