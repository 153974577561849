import { Dispatch, ReactElement, Ref, SetStateAction, forwardRef, useCallback, useEffect, useState } from "react"
import { useForm } from "react-hook-form";
import axios from "axios";
import { useTranslation } from "react-i18next";

import {
    AlertColor,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    IconButton,
    Slide,
    Slider,
    useMediaQuery,
    useTheme
} from "@mui/material"
import { TransitionProps } from "@mui/material/transitions";

// Componentes
import SnackBar from "Components/Snackbar";
import FirstSteps from "./Steps/First";
import TwoSteps from "./Steps/Second";
import ThirdStep from "./Steps/Third";
import FourthStep from "./Steps/Fourth";
import FifthStep from "./Steps/Fifth";

// Iconos
import CloseIcon from '@mui/icons-material/Close';
import DetailsUser from "./Steps/DetailsUser";
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

const Transition = forwardRef((
    props: TransitionProps & {
        children: ReactElement<any, any>;
    },
    ref: Ref<unknown>,
) => (<Slide in={true} direction="up" ref={ref} {...props} />));


interface PropsInterface {
    open: boolean
    setOpen: Dispatch<SetStateAction<boolean>>
    setReLoad: Dispatch<SetStateAction<boolean>>
    setData: Dispatch<SetStateAction<any>>
    data: any
    urlCredential: string
    urlCreate: string
    urlInformation: string
    token: string
    roleList: any
    canalList: any
}

const MaxValueProgress = 40
const RegexEmail =  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i
const RegexPassword = /^(?=(.*[A-Z]){2,})(?=(.*[a-z]){2,})(?=(.*\d){3,}).*$/

const UserInformationModal = (props: PropsInterface) => {
    const { t } = useTranslation()
    const { open, setOpen, setReLoad, data, urlCreate, urlCredential, urlInformation, token, setData, roleList, canalList } = props
    const { register, handleSubmit, setValue, formState: { errors }, reset, getValues, setError } = useForm()
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'))
    const [loader, setLoader] = useState(false)
    const [createMonitorAccount, setCreateAccountMonitor] = useState(false)

    const [user, setUser] = useState(data)

    const [userHasModified, setUserHasModified] = useState(false)
    const [loaderSendEmail, setLoaderSendEmail] = useState(false)

    // Metodo de notificacion
    const [detailsMessage, setDetailsMessage] = useState<{ type: AlertColor, title: string }>({
        type: "info",
        title: ""
    })
    const [openSnack, setOpenSnack] = useState(false)
    const [progressValue, setProgressValue] = useState(0)
    const [roleSelected, setRoleSelected] = useState<any>(null)

    const [messagesError, setMessagesError] = useState<any[]>([])

    const marks = [
        {
            value: 0,
            label: 'Paso 1',
        }, {
            value: 10,
            label: 'Paso 2',
        }, {
            value: 20,
            label: 'Paso 3',
        }, {
            value: 30,
            label: 'Paso 4',
        }, {
            value: 40,
            label: 'Paso 5',
        }
    ];
    useEffect(() => {
        if (open) {
            if (data !== null) {
                // En dado caso que se desee actualizar una cuenta
                setValue("name", data.name)
                setValue("lastName", data.last_name)
                setValue("document", data.dui)
                setValue("phone", data.phone)
                setValue("email", data.email)
                setValue("router", data.router ?? 0)
                // Le da el formato a los canales de venta
                if (data.hasOwnProperty("vendor_code")) {
                    setUser((prevState: any) => ({
                        ...prevState,
                        name: data?.name ?? "",
                        lastName: data?.last_name ?? "",
                        email: data?.email ?? "",
                        phone: data?.phone ?? "",
                        document: data?.dui ?? "",
                        priceList: data?.priceList ?? 0,
                        slp: data?.salesPersonCode ?? 0,
                        codeCellar: data?.codeCellar ?? "",
                        router: data?.router ?? 0,
                        disabled: data.disabled,
                        vendorCode: data?.vendor_code.split(",") ?? []
                    }))
                }

                // Le da el formato a los canales de compra
                if (data.hasOwnProperty("buyer_code")) {
                    setUser((prevState: any) => ({
                        ...prevState,
                        buyerCode: data?.buyer_code.split(",")
                    }))
                }
            } else {
                setUser({
                    phone: "",
                    document: ""
                })
                // Resetea los valores
                setProgressValue(0)
                setRoleSelected(null)
            }

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [open, data])
    // Metodo para cerrar el modal
    const handleClose = () => {
        setOpen(false)
        setData(null)
        setUser(null)
        if (userHasModified) setReLoad(true)
        setUserHasModified(false)
        reset()
    }

    const validateDataUser = async(element: any) => {
        if ( data !== null ) {
            saveDataUser(element)
            return
        }
        let errorsCopyList = []
        let attributes =[]
        // Valida que se tenga un rol seleccionado en el primer paso
        if (roleSelected === null && progressValue === 0) {
            setMessagesErrorList(t('userControl.text_11'), "role")
            return
        }

        // Metodo para validar el rol y los pasos
        if (progressValue === 0) {
            resetMessageError(["document", "password", "phone"])

            // Valida el documento
            if ( user.document === "" || user.document === null || user.document.length < 10 ) {
                setMessagesErrorList(t('userControl.text_11'), "document")
                return
            }

            // Valida el numero de telefono
            if ( user.phone === "" || user.phone === null || user.phone.length < 9 ) {
                setMessagesErrorList(t('userControl.text_11'), "phone")
                return
            }

            if ( !RegexPassword.test(element.password) ) {
                setMessagesErrorList(t('userControl.text_19'), "password")
                return
            }
            setUser((prevState:any) => ({
                ...prevState,
                ...element, 
                buyerCode: [], 
                vendorCode: [], 
                emailsNotification: [],
                roleMonitor: null
            }))
            // return
            setCreateAccountMonitor( roleSelected.id === "barlleno" )
            if (roleSelected.id === "barlleno") setProgressValue(prevState => prevState + 10)
        } else if (progressValue === 10) {
            attributes = ["priceList", "slp", "nameSAP", "codeCellar", "router", "roleMonitor"]
            resetMessageError(attributes)
            // Valida que tenga la lista de  precios
            if (!user.hasOwnProperty("priceList") || user.priceList === "" || user.priceList === null || isNaN(user.priceList)) {
                if ( !user.hasOwnProperty("priceList") ) errorsCopyList.push({ message: t('userControl.text_11'), id: "priceList"})
                else if (isNaN(user.priceList)) errorsCopyList.push({message:'La lista de precios tiene que ser un numero', id: "priceList"})
                else errorsCopyList.push({message:'Tiene que ingresar el numero de la lista de precios', id: "priceList"})
            }
        
            // Valida que tenga el codigo de persona
            if ( !["userStore", "displayer"].includes(roleSelected.id) ) {
                if (!user.hasOwnProperty("slp") || user.slp === "" || user.slp === null || isNaN(user.slp)) {
                    if (!user.hasOwnProperty("slp")) errorsCopyList.push({message: t('userControl.text_11'), id: "slp"})
                    else if (isNaN(user.slp)) errorsCopyList.push({message:'El codigo de persona tiene que ser un numero', id: "slp"})
                    else errorsCopyList.push({message:'Tiene que ingresar el codigo de persona', id: "slp"})
                }
            }

            // Valida que tenga el nombre de usuario para facturar
            if ( ["admin", "adminsupport", "adminComercial", "userStore"].includes(roleSelected.id) ) {
                if (!user.hasOwnProperty("nameSAP") || user.nameSAP === "" || user.nameSAP === null) {
                    if (!user.hasOwnProperty("nameSAP")) errorsCopyList.push({message:t('userControl.text_11'), id: "nameSAP" })
                    else errorsCopyList.push({message:'Tiene que ingresar el nombre de usuario para facturar', id: "nameSAP" })
                }
    
                // Valida que tenga el codigo de bodega
                if (!user.hasOwnProperty("codeCellar") || user.codeCellar === "" || user.codeCellar === null) {
                    if (!user.hasOwnProperty("codeCellar")) errorsCopyList.push({message:t('userControl.text_11'), id: "codeCellar" })
                    else errorsCopyList.push({message:'Tiene que ingresar el codigo de bodega', id: "codeCellar" })
                } else if ( user.codeCellar !== "BOT05" && user.codeCellar !== "BPP01" ) {
                    if ( user.codeCellar !== "BOT05" ) errorsCopyList.push({ message: 'El codigo de bodega no es valido', id: "codeCellar" })
                    else errorsCopyList.push({ message: 'El codigo de bodega no es valido', id: "codeCellar" })
                }
            }

            // Valida los canales de compra
            if ( ["admin", "adminsupport", "adminComercial"].includes(roleSelected.id) ) {
                if (!user.hasOwnProperty("buyerCode") || user.buyerCode.length === 0 ) {
                    if (!user.hasOwnProperty("buyerCode")) errorsCopyList.push({message:t('userControl.text_11'), id: "buyerCode" })
                    else errorsCopyList.push({message:'Tiene que ingresar un canal de compra', id: "buyerCode" })
                }
            } 

            // Valida los canales de venta
            if ( !["displayer"].includes(roleSelected.id) ) {
                if (!user.hasOwnProperty("vendorCode") || user.vendorCode.length === 0 ) {
                    if (!user.hasOwnProperty("vendorCode")) errorsCopyList.push({message:t('userControl.text_11'), id: "vendorCode" })
                    else errorsCopyList.push({message:'Tiene que ingresar un canal de venta', id: "vendorCode" })
                }
            } 
            
            // En dado caso que tenga estos roles valida que tenga una lista de ruta
            if ( ["admin", "adminsupport", "adminComercial", "displayerSupervisor", "displayer"].includes(roleSelected.id) ) {
                if (!user.hasOwnProperty("router") || user.router === "" || user.router === null || isNaN(user.router)) {
                    if (!user.hasOwnProperty("router")) errorsCopyList.push({message:t('userControl.text_11'), id: "router" })
                    else if ( isNaN(user.router) ) errorsCopyList.push({message:'El numero de ruta tiene que ser un numero', id: "router" })
                    else errorsCopyList.push({message:'Tiene que ingresar el numero de ruta', id: "router" })
                }
            } 
            errorsCopyList.forEach(item => setMessagesErrorList(item.message , item.id));

            if (valideIncludeMessages(attributes) || errorsCopyList.length > 0 ) return
        } else if (progressValue === 20) {
            if ( createMonitorAccount  ) {
                attributes = ["roleMonitor", "vendorCode", "codeCellar", "nameSAP"]
                resetMessageError(attributes)
                // Valida que tenga el rol asignado 
                if (!user.hasOwnProperty("roleMonitor") || user.roleMonitor === null || user.roleMonitor.id === "") {
                    if (!user.hasOwnProperty("roleMonitor") || user.roleMonitor === null) errorsCopyList.push({ message: t('userControl.text_11'), id: "roleMonitor" })
                    else errorsCopyList.push({ message: 'Tiene que seleccionar un rol', id: "roleMonitor" })
                }

                // Valida que tenga el codigo de bodega
                if (!user.hasOwnProperty("codeCellar") || user.codeCellar === "" || user.codeCellar === null) {
                    if (!user.hasOwnProperty("codeCellar")) errorsCopyList.push({ message: t('userControl.text_11'), id: "codeCellar" })
                    else errorsCopyList.push({ message: 'Tiene que ingresar el codigo de bodega', id: "codeCellar" }) 
                } else if ( user.codeCellar !== "BOT05" && user.codeCellar !== "BPP01" ) {
                    if ( user.codeCellar !== "BOT05" ) errorsCopyList.push({ message: 'El codigo de bodega no es valido', id: "codeCellar" })
                    else errorsCopyList.push({ message: 'El codigo de bodega no es valido', id: "codeCellar" })
                }

                // Valida las propiedades para poder facturar
                if ( user.roleMonitor?.id === "cajero" || roleSelected.id === "userStore" ) {
                    if (!user.hasOwnProperty("nameSAP") || user.nameSAP === "" || user.nameSAP === null) {
                        if (!user.hasOwnProperty("nameSAP")) errorsCopyList.push({message:t('userControl.text_11'), id: "nameSAP" })
                        else errorsCopyList.push({message:'Tiene que ingresar el nombre de usuario para facturar', id: "nameSAP" })
                    }
                }
                errorsCopyList.forEach(item => setMessagesErrorList(item.message , item.id));    
                if (valideIncludeMessages(attributes) || errorsCopyList.length > 0 ) return
            }
        }

        // Ultima validacion previo al guardado
        if (progressValue !== MaxValueProgress) {
            setProgressValue(prevState => prevState + 10)
            return
        }
        saveDataUser(element)
    }


    const setMessagesErrorList = (message: string, id: string) => {
        setMessagesError(prevState => {
            let values = prevState.filter((element: any) => element.id !== id)
            return [
                ...values,
                {
                    id: id,
                    message: message
                }
            ]
        })
    }

    const valideIncludeMessages = useCallback((id: string[]): boolean => {
        return messagesError.some((element: any) => id.includes(element.id))
        // eslint-disable-next-line
    },[])

    const resetMessageError = useCallback((id: string[]) => {
        setMessagesError(prevState => {
            return prevState.filter((element: any) => !id.includes(element.id))
        })
    }, [])
    
    // Metodo que recibe la informacion
    const saveDataUser = async (element: any) => {
        // eslint-disable-next-line 
        setLoader(true)
        let headers = {
            headers: {
                "Content-Type": "application/json",
                "Authorization": "UserToken " + token
            }
        }
        if (data === null) {
            // Para crear el nuevo usuario
            let body = {
                token: token,
                email: element.email,
                password: element.password,
                role: roleSelected.id,
                data: {
                    uid: token,
                    device: "web",
                    dui: user.document ?? "",
                    language: "es",
                    last_name: user.lastName ?? "",
                    name: user.name ?? "",
                    phone: user.phone ?? "",
                    priceList: user.priceList ?? null,
                    vendor_code: user.vendorCode?.join(",") ?? "-",
                    salesPersonCode:  Number(user.slp) ?? -1,
                    router: user.router ?? 0,
                    emails_notification: user.emailsNotification ?? [],
                    codeCellar: user.codeCellar ?? "",
                    vendorName: user.nameSAP ?? "",
                    buyer_code: user.buyerCode?.join(",") ?? "-",

                }
            }

            await axios.post(urlCreate, body).then((response) => {
                // En dado caso que todo se alla realizado de manera correcta
                setUserHasModified(true)
                setDetailsMessage({
                    title: response?.data?.message ?? t('userControl.text_36'),
                    type: "success"
                })
                setLoader(false)
                setOpenSnack(true)
                handleClose()
            }).catch((error) => {
                setDetailsMessage({
                    title: error?.response?.data?.message ?? t('userControl.text_37'),
                    type: "error"
                })
                setOpenSnack(true)
                setLoader(false)
            })
            setLoader(false)
        } else {
            // Para actualizar la informacion del usuario
            if (element.password !== "" || element.email !== data.email || data.disabled !== user?.disabled) {
                // Para actaulizar la contraseña
                let body: any = {
                    userKey: data.$key,
                }
                if (element.password !== "") {
                    // Valida el minimo de caracterres que debe de tener
                    if (element.password.length < 8) {
                        setError("password", { type: 'custom', message: t('userContro.text_19') })
                        setLoader(false)
                        return
                    }
                    body.password = element.password
                }
                if (element.email !== data.email) body.email = element.email
                if (element.disabled !== user?.disabled) body.disabled = user?.disabled

                // Valida que se agreguen elementos al body
                if (Object.keys(body).length > 1) {
                    // Realiza la ejecucion de la consulta
                    await axios.put(urlCredential, body, headers).then((response) => {
                        setUserHasModified(true)
                        setDetailsMessage({
                            title: response?.data?.message ?? t('userControl.text_35'),
                            type: "success"
                        })
                        setLoader(false)
                        setOpenSnack(true)
                    }).catch((error) => {
                        setDetailsMessage({
                            title: error?.response?.data?.message ?? t('userControl.text_38'),
                            type: "error"
                        })
                        setOpenSnack(true)
                        setLoader(false)
                    })
                }
                setLoader(false)
            }

            let userBody: any = {
                userKey: data.$key
            }

            // Para actualizar la informacion de la cuenta
            if (element.name !== data.name) userBody.name = element.name
            if (element.lastName !== data.last_name) userBody.lastName = element.lastName
            if (element.phone !== user.phone) userBody.phone = user.phone
            if (element.document !== user.dui) userBody.dui = user.document

            if (Object.keys(userBody).length > 1) {
                await axios.put(urlInformation, userBody, headers).then((response) => {
                    setDetailsMessage({
                        title: response?.data?.message ?? t('userControl.text_39'),
                        type: "success"
                    })
                    setUserHasModified(true)
                    setLoader(false)
                    setOpenSnack(true)
                }).catch((error) => {
                    setDetailsMessage({
                        title: error?.response?.data?.message ?? t('userControl.text_40'),
                        type: "error"
                    })
                    setLoader(false)
                    setOpenSnack(true)
                })
            } else setLoader(false)
        }
    }


    // Metodo para eliminar un elemento del snack de canales y correos
    const handleDelete = (item:any, type:string) => {
        if ( type === "buyerCode" ) {
            let newBuyerCode = user?.buyerCode?.filter((element: any) => element !== item)
            setUser((prev: any) => ({ ...prev, [type]: newBuyerCode }))
        } else if ( type === "vendorCode" ) {
            let newVendorCode = user?.vendorCode?.filter((element: any) => element !== item)
            setUser((prev: any) => ({ ...prev, [type]: newVendorCode }))
        } else {
            let newEmailsNotification = user?.emailsNotification?.filter((element: any) => element !== item)
            setUser((prev: any) => ({ ...prev, [type]: newEmailsNotification }))
        }
    }

    // Metodo para agrear los canales y correos
    const addCanal = (type: string, valueInput: string, setValueInput: Dispatch<SetStateAction<string>>) => {
        // Validar si el valor ya existe y si existe no lo agrega
        if (type === "buyerCode") {
            if (valueInput.length < 4) setMessagesErrorList("Canal no valido, tiene que tener un minimo de 4 caracteres", "buyerCode") 
            else {
                resetMessageError(["buyerCode"])
                let validate = user?.buyerCode?.find((item: any) => item === valueInput)
                if ( !validate ) setUser((prev: any) => ({ ...prev, [type]: [...prev[type], valueInput] }))
                setValueInput("")
            }
        } else if (type === "vendorCode") {
            if (valueInput.length < 4) setMessagesErrorList("Canal no valido, tiene que tener un minimo de 4 caracteres", "vendorCode") 
            else {
                resetMessageError(["vendorCode"])
                let validate = user?.vendorCode?.find((item: any) => item === valueInput)
                if ( !validate ) setUser((prev: any) => ({ ...prev, [type]: [...prev[type], valueInput] }))
                setValueInput("")
            }
        } else {
            if ( RegexEmail.test(valueInput) ) {
                resetMessageError(["emailsNotification"])
                let validate = user?.emailsNotification?.find((item: any) => item === valueInput)
                if ( !validate ) setUser((prev: any) => ({ ...prev, [type]: [...prev[type], valueInput] }))
                setValueInput("")
            } else setMessagesErrorList("Correo no valido", "emailsNotification")
        }
    }
    // Metodo para darle formato a los valores del slider
    const valuetext = (value: number): string => `${value}`;

    // Metodo para regresar en el Slider
    const handleBack = () => {
        if (progressValue === 20 && roleSelected.id === "barlleno") setProgressValue(prevState => prevState - 20)
        else setProgressValue(prevState => prevState - 10)
    }

    return (
        <>
            <SnackBar
                {...detailsMessage}
                open={openSnack}
                setOpen={setOpenSnack} />
            <Dialog
                className="modal-bills"
                open={open}
                TransitionComponent={Transition}
                maxWidth="md"
                fullWidth
                sx={{
                    '& .MuiDialog-container': {
                        display: 'flex',
                        flexDirection: 'column',
                        justifyContent: 'center',
                    },
                    '& .MuiPaper-root': {
                        minHeight: '450px', // Aplica minHeight al contenido del Dialog
                    },
                }}
                keepMounted
                fullScreen={fullScreen}
                onClose={(loader || loaderSendEmail) ? () => { } : handleClose}>
                <div className="header-modal">
                    {
                        (loader || loaderSendEmail) ?
                            <h3>{data === null ? t('userControl.text_8') : t('userControl.text_9')}</h3>
                            :
                            <>
                                <h3>{data === null ? t('userControl.text_5') : t('userControl.text_7')}</h3>
                                <IconButton sx={{ aspectRatio: "1/1", height: "50px", margin: "auto 0", right: 0 }} onClick={handleClose}>
                                    <CloseIcon />
                                </IconButton>
                            </>
                    }
                </div>
                {
                    data === null &&
                    <div className="slider-root-user">
                        <Slider
                            defaultValue={0}
                            getAriaValueText={valuetext}
                            step={10}
                            disabled
                            value={progressValue}
                            max={MaxValueProgress}
                            marks={marks}
                            sx={{
                                color: '#888',
                                "& .MuiSlider-thumb ": {
                                    color: '#112A56',
                                    width: '10px',
                                    height: '10px',
                                },
                                '&.Mui-disabled .MuiSlider-track': {
                                    color: '#112A56'
                                }
                            }}
                            valueLabelDisplay="off" />
                    </div>
                }
                <form onSubmit={handleSubmit(validateDataUser)}>
                    <DialogContent className="content-form-user">
                        {
                            // Ingreso de la informacion basica del usuario, nombre y demas 
                            (data !== null || progressValue === 0) &&
                                <FirstSteps
                                    errors={errors}
                                    t={t}
                                    register={register}
                                    getValues={getValues}
                                    data={data}
                                    regexEmail={RegexEmail}
                                    regexPassword={RegexPassword}
                                    loaderSendEmail={loaderSendEmail}
                                    setDetailsMessage={setDetailsMessage}
                                    setLoaderSendEmail={setLoaderSendEmail}
                                    setOpenSnack={setOpenSnack}
                                    roleList={roleList?.rolesList ?? []}
                                    messagesError={messagesError}
                                    roleSelected={roleSelected}
                                    setMessages={setMessagesError}
                                    setRoleSelected={setRoleSelected} 
                                    user={user}
                                    setUser={setUser}/>
                        }
                        {
                            // Configuracion de la cuenta de premier tools
                            (data === null && progressValue === 10) &&
                                <TwoSteps
                                    role={roleSelected}
                                    user={user}
                                    canalList={canalList}
                                    errorsList={messagesError}
                                    deleteList={handleDelete}
                                    addList={addCanal}
                                    setUser={setUser} />
                        }
                        {
                            // Configuracion de la cuenta de Barlleno Monitor
                            (data === null && progressValue === 20) &&
                                <ThirdStep
                                    user={user}
                                    role={roleSelected}
                                    errorsList={messagesError}
                                    createMonitorAccount={createMonitorAccount}
                                    setCreateAccountMonitor={setCreateAccountMonitor}
                                    deleteList={handleDelete}
                                    addList={addCanal}
                                    setUser={setUser} />
                        }
                        {
                            // Configuracion de la cuenta de Barlleno Monitor
                            (data === null && progressValue === 30) &&  <FourthStep />
                        }
                        {
                            // Confirmacion de la cuenta
                            (data === null && progressValue === 40) &&
                                <FifthStep 
                                    role={roleSelected}
                                    user={user} />
                        }
                        {
                            data !== null &&
                                <DetailsUser
                                    data={data}
                                    t={t}
                                    roleList={roleList?.rolesList ?? []}
                                    user={user}
                                    setUser={setUser} />
                        }
                    </DialogContent>
                    <DialogActions style={{ display: 'flex', justifyContent: 'space-between', width: "96%", margin: '-10px auto 10px' }}>
                        {
                            progressValue !== 0 &&
                            <Button
                                variant="back-button"
                                fullWidth
                                startIcon={<ArrowBackIosIcon />}
                                disabled={loader}
                                onClick={handleBack}>
                                {t('userControl.text_33')}
                            </Button>
                        }
                        <div></div>
                        <Button
                            variant="back-button"
                            fullWidth
                            endIcon={(!loader && progressValue !== MaxValueProgress && data ===null) ? <ArrowForwardIosIcon /> : null}
                            disabled={loader}
                            type="submit">
                            {
                                loader ?
                                    <div style={{ display: "flex", gap: "1rem", alignContent: "center", alignItems: "center" }}>
                                        <CircularProgress color="inherit" size="1.43rem" />
                                        <>{t('userControl.text_31')}</>
                                    </div>
                                    :
                                    data !== null ?
                                    <>{ t('benchmarks.text_13')}</>
                                    :
                                    <>{progressValue !== MaxValueProgress ? t('userControl.text_32') : t('benchmarks.text_13')}</>
                            }
                        </Button>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}

export default UserInformationModal