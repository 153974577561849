import { createTheme } from '@mui/material';

// Diferentes estilos para un componente
import { BackButtonStyles } from './MUI-Variants/BackButton';
import { SubmitButtonStyles } from './MUI-Variants/SubmitButton';
import { SecondSubmitButtonStyles } from './MUI-Variants/SecondSubmitButton';
import { CancelButtonStyles } from './MUI-Variants/CancelButton';
import { AcceptButtonStyles } from './MUI-Variants/AcceptButton';

const theme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    color: "white",
                    "& .MuiInputBase-root": {
                        fontFamily: '"Poppins", sans-serif',
                        color: "#173456",
                        fontWeight: 500,
                        backgroundColor: "#fafafa",
                        borderRadius: "15px",
                        display: "flex",
                        alignItems: "center",
                    },
                    '& label.Mui-focused': {
                        color: '#173456',
                    },
                    "& .MuiText": {
                        color: "#173456"
                    },
                    "& .MuiFormLabel-root": {
                        color: "#173456"
                    },
                    '& .MuiOutlinedInput-root': {
                        '& fieldset': {
                            color: '#173456',
                            borderColor: '#173456',
                        },
                        '&:hover fieldset': {
                            color: '#173456',
                            borderColor: '#173456',
                        },
                        '&.Mui-focused fieldset': {
                            color: '#173456',
                            borderColor: '#173456',
                        },
                    },
                }
            }
        },
        MuiSwitch: {
            styleOverrides: {
                root: {
                    width: 42,
                    height: 26,
                    padding: 0,
                    '& .MuiSwitch-switchBase': {
                        padding: 0,
                        margin: 2,
                        transitionDuration: '300ms',
                        '&.Mui-checked': {
                            transform: 'translateX(16px)',
                            color: '#fff',
                            '& + .MuiSwitch-track': {
                                backgroundColor: '#65C466',
                                opacity: 1,
                                border: 0,
                            },
                            '&.Mui-disabled + .MuiSwitch-track': {
                                opacity: 0.5,
                            },
                        },
                        '&.Mui-focusVisible .MuiSwitch-thumb': {
                            color: '#33cf4d',
                            border: '6px solid #fff',
                        },
                        '&.Mui-disabled .MuiSwitch-thumb': {
                            color: '#FAFAFA',
                        },
                        '&.Mui-disabled + .MuiSwitch-track': {
                            opacity: 0.7 ,
                        },
                    },
                    '& .MuiSwitch-thumb': {
                        boxSizing: 'border-box',
                        width: 22,
                        height: 22,
                    },
                    '& .MuiSwitch-track': {
                        borderRadius: 26 / 2,
                        backgroundColor: '#E9E9EA',
                        opacity: 1,
                    },
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: '"Poppins", sans-serif',
                    textTransform: 'capitalize',
                    fontWeight: 600,
                    letterSpacing: '0.7px',
                    height: '45px',
                    fontSize: '0.95rem',
                    "&:disabled": {
                        backgroundColor: "#cacaca",
                        color: "#303030"
                    },
                }
            },
            variants: [
                BackButtonStyles, 
                SubmitButtonStyles,
                SecondSubmitButtonStyles,
                CancelButtonStyles,
                AcceptButtonStyles
            ]
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    color: "#173456", 
                    '&.Mui-checked': { 
                        color: "#173456"
                    },
                    '&.Mui-disabled': {
                        opacity: 0.5,
                    },
                }
            }
        },
        MuiChip: {
            styleOverrides:{ 
                root: {
                    margin: "0 2px"
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: "#173456",
                    '&.Mui-checked': { 
                        color: "#173456"
                    },
                    '&.Mui-disabled': {
                        opacity: 0.5,
                    },
                }
            }
        },
        MuiPagination: {
            styleOverrides: {
                root: {
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    margin: "20px 0",
                    "& .MuiPaginationItem-root": {
                        color: "#666666",
                        fontFamily: '"Poppins", sans-serif',
                        fontWeight: 600,
                        letterSpacing: '0.7px',
                        "&.Mui-selected": {
                            backgroundColor: "#173456",
                            color: "#fff"
                        }
                    }
                }
            }
        },
    }
})

export default theme