import { Chip, ListItem, Stack, Switch } from "@mui/material";
import { Dispatch, SetStateAction } from "react";

interface PropsInterface { 
    data: any
    t: any
    roleList: any
    user: any
    setUser: Dispatch<SetStateAction<any>>
}
const DetailsUser = ( props: PropsInterface ) => {
    const { data, t, roleList, user, setUser } = props;

    const handleChangeStatusUser = () => {
        setUser((prevState:any)=>{
            return {
                ...prevState,
                disabled: !prevState?.disabled
            }
        })
    }

    return (
        <>
            <div className="forms-content">
                <h3>{t('userControl.text_21')}</h3>
                <p>{data?.lastSignInTime}</p>

                <h3>{t('userControl.text_22')}</h3>
                <p>{data?.lastRefreshTime}</p>

                <h3>{t('userControl.text_23')}</h3>
                <p>
                    {roleList.find((item: any) => item.id === data.role)?.label}
                </p>
                <h3>{t('userControl.text_24')}</h3>
                <div style={{ display: "flex", gap: "5px" }}>
                    <Switch
                        value={!user?.disabled}
                        checked={!user?.disabled}
                        onClick={handleChangeStatusUser} />
                    <p> {!user?.disabled ? t('userControl.text_25') : t('userControl.text_26')} </p>
                </div>
            </div>
            <div style={{ marginBottom: "20px" }}>
                <h3>{t('userControl.text_28')}</h3>
                {
                    user?.vendorCode?.length > 0 ?
                        <Stack className="code-users">
                            {
                                user?.vendorCode?.map((item: any, index: number) => (
                                    <ListItem key={index}>
                                        <Chip label={item} />
                                    </ListItem>
                                ))
                            }
                        </Stack>
                        :
                        <p>{t('userControl.text_29')}</p>
                }
            </div>
            <div>
                {
                    user?.vendorCode?.length > 0 ?
                        <Stack className="code-users">
                            {
                                user?.buyerCode?.map((item: any, index: number) => {
                                    return (
                                        <ListItem key={index}>
                                            <Chip label={item} />
                                        </ListItem>
                                    )
                                })

                            }
                        </Stack>
                        :
                        <p>{t('userControl.text_29')}</p>
                }
            </div></>
    )
}

export default DetailsUser