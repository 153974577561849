import { useContext, useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import UserContextP from "Interfaces/Context"
import axios from "axios"

import {
    Button,
    Grow,
    Pagination,
    Stack,
} from "@mui/material"

// Componentes
import Loader from "Components/Loader"
import ErrorPage from "Components/NotAccesible/ErrorPage"
import UserInformationModal from "../Admin/UserControl/UserInformationModal"

// Iconos
import NotData from "assets/images/Comercial/no-data.png"
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import PlayCircleIcon from '@mui/icons-material/PlayCircle';
import SearchField from "Components/SearchField"

const UsersSupport = () => {
    const context = useContext(UserContextP)
    const { t } = useTranslation()

    const [listUser, setListUser] = useState<any[]>([])
    const [copyListUser, setCopyListUser] = useState<any[]>([])
    const [error, setError] = useState(false)
    const [loader, setLoader] = useState(true)
    const [pagination, setPagination] = useState(1)
    const [openDetailsUser, setOpenDetailsUser] = useState(false)
    const [userSelected, setUserSelected] = useState(null)
    const [elementsToShow, setElementsToShow] = useState(0)

    const [listAllRoles, setListAllRoles] = useState<any[]>([])
    const [listAllCanals, setListAllCanals] = useState<any[]>([])

    const [text, setText] = useState("")
    const [empty, setEmpty] = useState(false)

    useMemo(() => {
        if (window.innerWidth >= 1250) setElementsToShow(15)
        else if (window.innerWidth > 610 && window.innerWidth < 1250) setElementsToShow(12)
        else if (window.innerWidth <= 610) setElementsToShow(7)
        // eslint-disable-next-line
    }, [window.innerWidth])

    useEffect(() => {
        if (loader) getData()
        // eslint-disable-next-line
    }, [loader])

    const getData = async () => {
        let body: any = {
            token: context.user.id,
            params: []
        }
        let listRequest = [
            {
                url: context.service.router.POST.get_list_user,
                method: "POST",
                headers: { "content-type": "application/json" },
                body: body,
                callBack: (response: any) => {
                    setListUser(response.data.data)
                    setCopyListUser(response.data.data)
                    setLoader(false)
                },
                error: (_: any) => {
                    setError(true)
                    setLoader(false)
                }
            }, {
                url: context.service.router.GET.get_list_roles,
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    "Authorization": `UserToken ${context.user.id}`
                },
                body: {},
                callBack: (response: any) => {
                    setListAllRoles(response.data.data)
                },
                error: (_: any) => {
                    setError(true)
                    setLoader(false)
                }
            }, {
                url: context.service.router.GET.all_canals,
                method: "GET",
                headers: {
                    "content-type": "application/json",
                    "Authorization": `UserToken ${context.user.id}`
                },
                body: {},
                callBack: (response: any) => {
                    setListAllCanals(response.data.data)
                },
                error: (_: any) => {
                    setError(true)
                    setLoader(false)
                }
            }
        ]
        let promises: any = []

        if (context.user.role === "adminsupport") body.params = ["vendor", "displayer", "userStore", "admin", "buyer", "displayerSupervisor", "cellarmanager", "delivery"]
        else if (context.user.role === "admin" || context.user.role === "adminComercial" ) body.params = ["vendor", "displayer", "userStore", "buyer", "displayerSupervisor", "cellarmanager", "delivery"]
        else if (context.user.role === "displayerSupervisor") body.params = ["displayer"]

        listRequest.forEach((item: any) => {
            promises.push(axios({
                method: item.method,
                url: item.url,
                headers: item.headers,
                data: item.body
            }).then(item.callBack).catch(item.error))
        })
        await Promise.all(promises)
    }

    return (
        <>
            <UserInformationModal
                open={openDetailsUser}
                roleList={listAllRoles}
                setOpen={setOpenDetailsUser}
                setReLoad={setLoader}
                data={userSelected}
                setData={setUserSelected}
                canalList={listAllCanals}
                urlCreate={context.service?.router?.CREATE?.account ?? ""}
                urlCredential={context.service?.router?.UPDATE?.user_credentials ?? ""}
                urlInformation={context.service?.router?.UPDATE?.user_information ?? ""}
                token={context?.user?.id ?? ""} />
            {
                loader ?
                    <Loader
                        inPage={true}
                        openLoader={loader}
                        redirect={false}
                        redirectHome={false}
                        quantity={0} />
                    :
                    error ?
                        <ErrorPage message={t("benchmarks.text_1")} />
                        :
                        <>
                            {
                                copyListUser.length > 0 ?
                                    <>
                                    <div style={{ display: "grid", gridTemplateColumns: "54% 44%", width:"100%", alignItems:"center", marginBottom: "30px", gap: "2%"}}>
                                        <SearchField 
                                            text={text}
                                            setText={setText}
                                            useFilters={false}
                                            data={copyListUser}
                                            result={listUser}
                                            empty={empty}
                                            setResult={setListUser}
                                            setPagination={setPagination}
                                            setEmpty={setEmpty}
                                            fullWidth/>
                                        <Button 
                                            variant="second-submit-button"
                                            startIcon={<AddIcon/>}
                                            onClick={() => setOpenDetailsUser(true)}>
                                            Crear cuenta nueva
                                        </Button>
                                    </div>
                                        <div className="content-user-list">
                                            {
                                                listUser?.map((item: any, index: number) => {
                                                    return (
                                                        index >= (elementsToShow * pagination - elementsToShow) && index < (elementsToShow * pagination) ?
                                                            <Grow
                                                                in={index >= (elementsToShow * pagination - elementsToShow) && index < (elementsToShow * pagination)}
                                                                key={index}
                                                                className="list-items-users"
                                                                onClick={() => { }}
                                                                style={{ transformOrigin: '0 0 0' }}
                                                                {...(true ? { timeout: ((1) * 190) + 190 } : {})}>
                                                                <div className="content-card-details-user">
                                                                    <div className="icon-contianer">
                                                                        <PersonIcon sx={{ fontSize: "35px", padding: "15px 0", color: "#FFFFFF" }} />
                                                                    </div>
                                                                    <div>
                                                                        <div>
                                                                            <div style={{ display: "flex", gap: "10px" }}>
                                                                                <h3 style={{ display:"flex", alignItems: "center", gap: '10px'}}>
                                                                                    <div className={"ciruclar-point-base " + (!item.disabled ? "ciruclar-point-active" : "ciruclar-point-deactive ")}/> {item.name} {item.last_name}
                                                                                </h3>
                                                                            </div>
                                                                            <p>{item.email}</p>
                                                                            <p style={{ textTransform:"uppercase" }}>{item.roleName}</p>
                                                                        </div>
                                                                    </div>
                                                                    <div
                                                                        className="icon-item"
                                                                        onClick={() => {
                                                                            setOpenDetailsUser(true)
                                                                            setUserSelected(item)
                                                                        }}>
                                                                        <PlayCircleIcon style={{ margin: "auto", right: 0, color: "#183555" }} />
                                                                    </div>
                                                                </div>
                                                            </Grow>
                                                            :
                                                            null
                                                    )
                                                })
                                            }
                                        </div>
                                        <div style={{ display: "flex", justifyContent: "center", marginTop: "50px" }}>
                                            <Stack spacing={2} sx={{ alignContent: "center", margin: "auto" }}>
                                                <Pagination 
                                                    count={Math.ceil(listUser.length / 15)} 
                                                    defaultPage={pagination} 
                                                    onChange={(_, value) => setPagination(value)} />
                                            </Stack>
                                        </div>
                                    </>
                                    :
                                    <div className="not-order" >
                                        <img src={NotData} alt="empty" />
                                        <p>No hay usuarios para mostrar</p>
                                    </div>
                            }
                        </>
            }
        </>
    )
}

export default UsersSupport