import { Dispatch, SetStateAction, useState } from "react";

import { AlertColor, Autocomplete, CircularProgress, IconButton, InputAdornment, TextField } from "@mui/material";

// Componentes/
import MessageError from "../MessageError";

// Iconos
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

// Servicios y libreriras
import { getAuth, sendPasswordResetEmail } from "firebase/auth";
import { FieldErrors, FieldValues, UseFormGetValues, UseFormRegister } from "react-hook-form";
import MaskedTextField from "Components/TextMask";
// import MaskedInputText from "Components/MaskInputText";

interface OneStepsProps {
    errors: FieldErrors<FieldValues>
    t: any
    register: UseFormRegister<FieldValues>
    getValues: UseFormGetValues<FieldValues>
    data: any
    loaderSendEmail: boolean
    setDetailsMessage: Dispatch<SetStateAction<{ type: AlertColor, title: string }>>
    setLoaderSendEmail: Dispatch<SetStateAction<boolean>>
    setOpenSnack: Dispatch<SetStateAction<boolean>>
    roleList: any[]

    messagesError?: any[]
    roleSelected: any
    setRoleSelected: Dispatch<SetStateAction<any>>
    setMessages?: Dispatch<SetStateAction<any>>

    regexPassword: RegExp
    regexEmail: RegExp

    user: any
    setUser: Dispatch<SetStateAction<any>>
}

const FirstStep = (props: OneStepsProps) => {
    const {
        errors,
        t,
        register,
        getValues,
        data,
        loaderSendEmail,
        roleSelected,
        messagesError,
        setMessages,
        setRoleSelected,
        setLoaderSendEmail,
        setOpenSnack,
        setDetailsMessage,
        roleList,
        regexPassword,
        user,
        setUser
    } = props;
    const [showPassword, setShowPassword] = useState(false);

    // Metodo para enviar correoo a la cuenta para restablecer la contraseña
    const resetPasswordByEmail = async () => {
        setLoaderSendEmail(true)
        sendPasswordResetEmail(getAuth(), getValues("email"))
            .then(() => {
                setLoaderSendEmail(false)
                setDetailsMessage({
                    title: "Mensaje enviado correctamente para reestablecer la contraseña",
                    type: "success"
                })
                setOpenSnack(true)
            })
            .catch((error) => {
                setLoaderSendEmail(false)
                setDetailsMessage({
                    title: error?.response?.data?.message ?? "Error al enviar el correo, intente nuevamente o contacte con soporte",
                    type: "error"
                })
                setOpenSnack(true)
            });
    }

    return (
        <>
            <h5>Informacion basica de la cuenta</h5>
            <div className="forms-content" style={{ marginTop: "30px" }}>
                <TextField
                    autoFocus
                    helperText={<MessageError message={errors?.name?.message?.toString() ?? ""} />}
                    label={t('userControl.text_10')}
                    InputLabelProps={{ shrink: true }} 
                    {...register("name", {
                        required: {
                            value: true,
                            message: t("userControl.text_11")
                        }
                    })} />
                <TextField
                    helperText={<MessageError message={errors?.lastName?.message?.toString() ?? ""} />}
                    autoFocus
                    label={t("userControl.text_12")}
                    InputLabelProps={{ shrink: true }} 
                    {...register("lastName", {
                        required: {
                            value: true,
                            message: t("userControl.text_11")
                        }
                    })} />
                <div style={{ marginBottom:"15px"}}>
                    <MaskedTextField
                        label={t("userControl.text_14")}
                        fullWidth
                        InputLabelProps={{ shrink: true }} 
                        value={user?.document ?? ""}
                        onChange={(e: any) => setUser((prev: any) => ({ ...prev, document: e.target.value }))}
                        mask="00000000-0" />
                    <MessageError message={ messagesError?.find(item => item.id === "document")?.message ?? "" } />
                </div>

                <div style={{ marginBottom:"15px"}}>
                    <MaskedTextField
                        label={t('userControl.text_15')}
                        InputLabelProps={{ shrink: true }} 
                        fullWidth
                        value={user?.phone ?? ""}
                        onChange={(e: any) => setUser((prev: any) => ({ ...prev, phone: e.target.value }))}
                        mask="0000-0000" />
                    <MessageError message={ messagesError?.find(item => item.id === "phone")?.message ?? "" } />
                </div>
            </div>
            <TextField
                helperText={<MessageError message={errors?.email?.message?.toString() ?? ""} />}
                autoFocus
                label={t('perfil.text_2')}
                style={{ marginBottom: "1rem" }}
                fullWidth
                InputLabelProps={{ shrink: true }} 
                {...register("email", {
                    required: {
                        value: true,
                        message: t("userControl.text_11")
                    },
                    pattern: {
                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                        message: t("userControl.text_17")
                    }
                })} />
            <div className={data !== null ? "password-container" : "password-container-2"}>
                <TextField
                    helperText={<MessageError message={errors?.password?.message?.toString() ?? ""} />}
                    label={t('userControl.text_18')}
                    autoFocus
                    fullWidth
                    type={showPassword ? "text" : "password"}
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)}>
                                    {showPassword ? <VisibilityOffIcon style={{ color: "#173456" }} /> : <VisibilityIcon style={{ color: "#173456" }} />}
                                </IconButton>
                            </InputAdornment>
                        ),
                    }}
                    {
                    ...register("password", {
                        required: {
                            value: data === null,
                            message: t('userControl.text_11')
                        },
                        minLength: {
                            value: getValues("password") !== "" ? 8 : 0,
                            message: t('userControl.text_19')
                        },
                        pattern: {
                            value: getValues("password") !== "" ? regexPassword : /.*/,
                            message: t('userControl.text_20')
                        }
                    })
                    }
                />
                {
                    data !== null &&
                    <>
                        {
                            loaderSendEmail ?
                                <CircularProgress />
                                :
                                <IconButton sx={{ width: "40px" }} onClick={resetPasswordByEmail}>
                                    <ForwardToInboxIcon />
                                </IconButton>
                        }
                    </>
                }
            </div>
            {
                data === null &&
                <div className="forms-content">
                    <p>Seleccione el rol</p>
                    <div>
                        <Autocomplete
                            value={roleSelected}
                            onChange={(_, newValue) => {
                                if (setRoleSelected) setRoleSelected(newValue)
                                if (setMessages) setMessages((prevState: any) => prevState?.filter((item: any) => item.id !== "role" ?? []))
                            }}
                            renderInput={({ inputProps, ...rest }) => (
                                <TextField
                                    {...rest}
                                    inputProps={{ ...inputProps, readOnly: true }} />
                            )}
                            options={roleList} />
                        <MessageError message={messagesError?.find(item => item.id === "role")?.message ?? ""} />
                    </div>
                </div>
            }
        </>
    );
}

export default FirstStep;