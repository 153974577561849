import { initReactI18next } from 'react-i18next';
import i18n from "i18next";

//importacion de los lenguajes
import es from "assets/translations/es.json";
import en from "assets/translations/en.json";
import LanguageDetector from 'i18next-browser-languagedetector';

export const translate = ( lenguage:string ) => {
    return i18n
    .use(LanguageDetector)
    .use(initReactI18next).init({
        resources: {
            en: {
                translation: en
            },
            es: {
                translation: es
            }
        },
        lng: lenguage,
        fallbackLng: "es",
        interpolation: {
            escapeValue: false
        },
        backend: {
            loadPath: "/assets/translations/{{lng}}.json",
            allowMultiLoading: false,
        },
        saveMissing: false,
        react: {
            useSuspense: false,
        },
    });

}