export const AcceptButtonStyles: any = {
    props: { variant: 'accept-button' },
    style: {
        backgroundColor: "#32d65f",
        color: 'white',
        '&:hover': {
            backgroundColor: '#cbf7d7',
            color: '#32d65f',
            border: '1px solid #32d65f'
        }
    },
}