import { Dispatch, SetStateAction, useState } from "react"

import { Autocomplete, Checkbox, Chip, Fade, IconButton, InputAdornment, TextField } from "@mui/material"
import AddIcon from '@mui/icons-material/Add';
import MessageError from "../MessageError";


const RoleList = [
    {
        id: "admin",
        label: "Administrador"
    },{
        id: "cajero",
        label: "Cajero"
    }
]
interface PropsInterface {
    user: any
    role: any
    setUser: Dispatch<SetStateAction<any>>
    errorsList: any[]
    createMonitorAccount: boolean
    setCreateAccountMonitor: Dispatch<SetStateAction<boolean>>
    deleteList: (item:any, type:string) => void
    addList: (type: string, valueInput: string, setValueInput: Dispatch<SetStateAction<string>>) => void
}

const ThirdStep = ( props: PropsInterface ) => {
    const { errorsList, addList, role, user, setUser, createMonitorAccount, setCreateAccountMonitor, deleteList  } = props;

    const [valueCanalVendor, setValueCanalVendor] = useState<string>("");

    return (
        <>
            <h5>Configuracion de la cuenta de Barlleno Monitor</h5>
            <div className="third-modal-user">
                <div className="header-title">
                    <p>Creacion de cuenta para monitor</p>
                    <Checkbox
                        disabled={role?.id === "barlleno"}
                        checked={createMonitorAccount}
                        onClick={() => setCreateAccountMonitor(!createMonitorAccount)} />
                </div>
                <Fade in={createMonitorAccount}>
                    <div style={{ marginTop:"20px" }}>
                        <div className="form-second-user">
                            <p>Seleccione el rol</p>
                            <Autocomplete
                                value={user?.roleMonitor ?? null}
                                options={RoleList} 
                                onChange={(_, newValue) => setUser((prevState:any)=>({...prevState, roleMonitor: newValue})) }
                                getOptionLabel={(option) => option.label}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                renderInput={({ inputProps, ...rest }) => (
                                    <TextField
                                        {...rest}
                                        helperText={<MessageError message={errorsList?.find(item => item.id === "roleMonitor")?.message ?? ""} />}
                                        inputProps={inputProps} />
                                )}/>

                            <p>Codigo de bodega</p>
                            <TextField
                                name="codeCellar"
                                helperText={<MessageError message={errorsList?.find(item => item.id === "codeCellar")?.message ?? ""} />}
                                value={user?.codeCellar ?? ""}
                                onChange={(e) => setUser((prev: any) => ({ ...prev, codeCellar: e.target.value?.toUpperCase() }))} />

                            <p>Nombre de usuario para facturar</p>
                            <TextField
                                name="nameSAP"
                                helperText={<MessageError message={errorsList?.find(item => item.id === "nameSAP")?.message ?? ""} />}
                                value={user?.nameSAP ?? ""}
                                onChange={(e) => setUser((prev: any) => ({ ...prev, nameSAP: e.target.value }))} />

                            <p>Codigos de canales venta</p>
                            <TextField
                                name="vendorCode"
                                value={valueCanalVendor}
                                onChange={(e) => setValueCanalVendor(e.target.value)}
                                helperText={<MessageError message={errorsList?.find(item => item.id === "vendorCode")?.message ?? ""} />}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                        <IconButton onClick={() => addList("vendorCode", valueCanalVendor, setValueCanalVendor)}>
                                        <AddIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    )
                                }} />
                            <div></div>
                            <div>
                                {
                                    user?.vendorCode?.map((item: any, index: number) => (
                                        <Chip key={index} label={item} onDelete={() =>deleteList(item, "vendorCode")} />
                                    ))
                                }
                            </div>

                        </div>
                    </div>
                </Fade>
            </div>
        </>
    )
}

export default ThirdStep