import axios from "axios";
import { getAuth, signInWithEmailAndPassword, signOut } from "firebase/auth";
import { app } from "./Firebase";
export const auth = getAuth(app);

export const getDataInitialUser = async (uid: any, url: any) => {
	let body = {
        token: uid,
        data: {
			uid: uid,
			appId: "-", 
			appVersion: "0.6.1"
		},
	};
	let user = await axios.post(url, body).then((response: any) => {
			let info = {
				id: response.data.data.$key,
				last_name: response.data.data.last_name,
				name: response.data.data.name,
				email: response.data.data.email,
				vendorCode: response.data.data.vendor_code,
				salesPersonCode: response.data.data.salesPersonCode,
				priceList: response.data.data.priceList,
				branchOffice: response.data.data.branchOffice ? response.data.data.branchOffice : 0,
				role: response.data.data.role ?? "",
				codeCellar: response.data.data.WarehouseCode ?? "",
				phone: response.data.data.phone ?? "",
				documentUser: response.data.data.dui ?? "",
				creationDate: response.data.data.created_at ?? "",
				lastLogin: response.data.data.last_login ?? "",
				device:  response.data.data.device ?? "",
				vendorName: response.data.data.vendorName ?? "",
			};
			return info;
		})
		.catch((error: any) => {
			return 500;
		});
	return user;
};

export const login = async (email: string, password: string) => {
	let user = signInWithEmailAndPassword(auth, email, password)
		.then(async (userCredential) => {
			return {
				uid: await userCredential.user.uid,
				auth: true,
			};
		})
		.catch((error) => {
			return 400;
		});
	return user;
};

export const signout = () => {
	signOut(auth)
		.then((data) => {
			return;
		})
		.catch((error) => {
		});
};
export const validateSession = () => {
	auth.onAuthStateChanged(async (firebaseUser) => {
		let userData = {
			uid: await firebaseUser?.uid,
			auth: (await firebaseUser?.uid) ? true : false,
		};
		return userData;
	});
};

export { };
