import WarningAmberOutlinedIcon from '@mui/icons-material/WarningAmberOutlined';

interface PropsInterface {
    message:string;
}
const ErrorPage = ( props: PropsInterface ) => {
    return (
        <div style={{ width: '100%', margin: '40% auto', textAlign: 'center', }}>
            <WarningAmberOutlinedIcon sx={{ color: 'red', fontSize: '400%' }} />
            <p> { props.message } </p>
        </div>
    )
}
export default ErrorPage;