import { useTranslation } from "react-i18next";

// Iconos
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PersonIcon from '@mui/icons-material/Person';
import RampRightIcon from '@mui/icons-material/RampRight';
import StoreMallDirectoryIcon from '@mui/icons-material/StoreMallDirectory';
import { Chip, Stack } from "@mui/material";

interface PropsInterface {
    user: any
    role: any
    // setUser: Dispatch<SetStateAction<any>>
    // errorsList: any[]
    // createMonitorAccount: boolean
    // setCreateAccountMonitor: Dispatch<SetStateAction<boolean>>
    // deleteList: (item:any, type:string) => void
    // addList: (type: string, valueInput: string, setValueInput: Dispatch<SetStateAction<string>>) => void
}

const FifthStep = (props: PropsInterface) => {
    const { user, role } = props;
    const { t } = useTranslation();

    return (
        <>
            {/* <h3>Confirmacion de la cuenta</h3> */}
            <h3>Informacion de la persona</h3>
            <div className="user-details-card">
                <div className="two-details-confirmation-user">
                    <span>{t("userControl.text_10")}:</span>
                    <p>{user?.name ?? ""}</p>

                    <span>{t("userControl.text_12")}:</span>
                    <p>{user?.lastName ?? ""}</p>

                    <span>{t("perfil.text_2")}:</span>
                    <p>{user?.email ?? ""}</p>

                    <span>{t("userControl.text_14")}:</span>
                    <p>{user?.document ?? ""}</p>

                    <span>{t("userControl.text_15")}:</span>
                    <p>{user?.phone ?? ""}</p>
                </div>
            </div>

            <h3>Detalles del rol</h3>
            <div  className="user-details-card">
                <div className="role-details-user">
                    <div className="card-role">
                        <h3>PremierTools</h3>
                        <p>{role?.label ?? ""}</p>
                    </div>
                    <div  className="card-role">
                        <h3>Barlleno</h3>
                        <p>{user?.roleMonitor?.label ?? "-"}</p>
                    </div>
                </div>
            </div>


            <h3>Detalles de la cuenta de PremierTools</h3>
            <div className="user-details-card">
                <div className="two-details-confirmation-user">
                    <span>Canales de Compra</span>
                    <Stack direction="row" spacing={1}>
                        { user?.buyerCode?.map((item:any, index:number) => (<Chip key={index} label={item} />))}
                    </Stack>

                    
                    <span>Canales de Venta</span>
                    <Stack direction="row" spacing={1}>
                        { user?.vendorCode?.map((item:any, index:number) => (<Chip key={index} label={item} />))}
                    </Stack>

                    <span>Correos</span>

                    <Stack direction="row" spacing={1}>
                        { user?.emailsNotification?.map((item:any, index:number) => (<Chip key={index} label={item} />))}
                    </Stack>
                </div>

                <div className="user-details">
                    <div className="class-content">
                        <div className="details">
                            <RequestQuoteIcon sx={{ fontSize: "40px", color: "#112A56" }} />
                            <div>
                                <h4>Lista de precios</h4>
                                <p>{user?.priceList ?? ""}</p>
                            </div>
                        </div>
                    </div>
                    <div className="class-content">
                        <div className="details">
                            <PersonIcon sx={{ fontSize: "40px", color: "#112A56" }} />
                            <div>
                                <h4>Codigo de SAP</h4>
                                <p>{user?.slp ?? ""}</p>
                            </div>
                        </div>
                    </div>
                    <div className="class-content">
                        <div className="details">
                            <RampRightIcon sx={{ fontSize: "40px", color: "#112A56" }} />
                            <div>
                                <h4>Numero de ruta</h4>
                                <p>{user?.router ?? ""}</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="user-details">
                    <div className="class-content">
                        <div className="details">
                            <StoreMallDirectoryIcon sx={{ fontSize: "40px", color: "#112A56" }} />
                            <div>
                                <h4>Codigo de bodega</h4>
                                <p>{user?.codeCellar ?? ""}</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default FifthStep