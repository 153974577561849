import { useContext, useEffect, useState } from "react"
import { useTranslation } from "react-i18next"
import axios from "axios"

import { 
    Grow, 
    Pagination, 
    Stack 
} from "@mui/material"

import UserContextP from "Interfaces/Context"

// Componentes
import Loader from "Components/Loader"
import SearchField from "Components/SearchField"
import ErrorPage from "Components/NotAccesible/ErrorPage"
import ItemOrder from "Components/Document/ItemOrder"

const InformationRequest = () => {
    const { t } = useTranslation()
    const context = useContext(UserContextP)
    const [data, setData] = useState<any>([])
    const [copyData, setCopyData] = useState<any>([])
    const [empty, setEmpty] = useState(false)
    const [search, setSearch] = useState<string>('')
    const [loader, setLoader] = useState<boolean>(true)
    const [error, setError] = useState<boolean>(false)
    const [pagination, setPagination] = useState(1)


    useEffect(() => {
        if (context.service?.router?.POST?.get_inventory && context.authenticate.uid !== "" ) getDataInitial()
        // eslint-disable-next-line
    }, [ context.service?.router?.POST?.get_inventory, context.authenticate.uid ])

    const getDataInitial = async () => {
        let body = {
            token: context.authenticate.uid,
            data: {
                priceList: context?.user?.priceList ?? 0
            }
        }
        await axios.post(context.service?.router?.POST?.get_inventory, body).then((response) => {
            setData(response.data.data)
            setCopyData(response.data.data)
            setLoader(false)
        }).catch((error) => {
            setLoader(false)
            setError(true)
           
        })
    }
    //Le coloca una paginacion a la ventana
    const changePagination = (event: any, value: any) => {
        setPagination(value)
    }

    return (
        <>
            {
                loader ?
                    <Loader
                        inPage={true}
                        openLoader={loader}
                        redirect={false}
                        redirectHome={false}
                        quantity={0}
                    />
                    :
                    error ?
                        <ErrorPage message={t("benchmarks.text_1")} />
                        :
                        <>
                            <div className="search-bar">
                                <SearchField
                                    text={search}
                                    setText={setSearch}
                                    placeholder={t("consulta de informacion.text_2")}
                                    size="small"
                                    useFilters={false}
                                    data={copyData}
                                    result={data}
                                    empty={empty}
                                    setResult={setData}
                                    setEmpty={setEmpty}
                                    fullWidth={true}
                                    setPagination={setPagination} 
                                />
                                <Stack spacing={2} sx={{ alignContent: "center", alignItems: "center", marginTop: "10px", marginBottom: "20px" }}>
                                    <Pagination count={Math.ceil(data.length / 21)} defaultPage={1} siblingCount={0} onChange={changePagination} />
                                </Stack>
                            </div>
                            <div>
                                {
                                    data.length === 0 ?
                                    <>
                                        <p>{t("consulta de informacion.text_3")}</p>
                                    </>
                                    :
                                    <>
                                    <div className="items-order-list">
                                        {
                                            data.map((item: any, index: any) => {
                                                return (
                                                    index >= (21 * pagination - 21) && index < (21 * pagination) ?
                                                        <Grow
                                                            in={true}
                                                            key={index}
                                                            style={{ transformOrigin: '0 0 0' }}
                                                            {...(true ? { timeout: ((1) * 150) + 150 } : {})}
                                                        >
                                                            <div>
                                                                <ItemOrder 
                                                                    key={index}
                                                                    item={item} 
                                                                    productSelected={()=> {}} 
                                                                    showCheckbox={false} 
                                                                    showDetails={true} 
                                                                    showPrice={false}
                                                                />
                                                            </div>
                                                        </Grow>
                                                        :
                                                        <div key={index} style={{ display: "none"}}></div>
                                                )
                                            })
                                        }
                                    </div>
                                    </>
                                }
                            </div>
                        </>
            }
        </>
    )
}

export default InformationRequest