import React, { Dispatch, SetStateAction } from "react"
import {
    Snackbar 
} from '@mui/material';
import MuiAlert, { AlertColor, AlertProps } from '@mui/material/Alert';

interface PropsInterface {
    open: boolean;
    type:AlertColor ;
    title: string;
    setOpen:Dispatch<SetStateAction<boolean>>
}
const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
    props,
    ref,
  ) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
  });
  

const SnackBar = (props: PropsInterface) =>{
    const handleClose =(event?: React.SyntheticEvent | Event, reason?: string) =>{
        if (reason === 'clickaway') {
            return;
          }
          props.setOpen(false);
    }
    return(
        <div>
            <Snackbar open={props.open} autoHideDuration={6000} onClose={handleClose} style={{marginBottom:'25px'}}>
                <Alert onClose={handleClose} severity={ props.type } sx={{ width: '100%' }}>
                    { props.title }
                </Alert>
            </Snackbar>
        </div>
    )
}
export default SnackBar;