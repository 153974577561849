import ErrorIcon from '@mui/icons-material/Error';
const MessageError = ({ message }: { message: string }) => {
    return (
        <>
            {
                message !== "" &&
                    <div className="error-message">
                        <ErrorIcon sx={{ fontSize: '15px' }}/>
                        <p>{message}</p>
                    </div>
            }
        </>
    )
}

export default MessageError