import React, { forwardRef } from "react";
import { IMaskInput } from "react-imask";
import { TextField, TextFieldProps } from "@mui/material";

interface CustomProps {
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
    mask: string;
}

const TextMaskCustom = forwardRef<HTMLInputElement, CustomProps>(
    function TextMaskCustom(props, ref) {
        const { mask, onChange, ...other } = props;
        return (
            <IMaskInput
                {...other}
                mask={mask}
                definitions={{
                    '#': /[1-9]/,
                }}
                inputRef={ref}
                onAccept={(value: any) => onChange({ target: { name: props.name, value } })}
                overwrite
            />
        );
    },
);

interface MaskedTextFieldProps extends Omit<TextFieldProps, 'onChange'> {
    mask: string;
    onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const MaskedTextField: React.FC<MaskedTextFieldProps> = ({ mask, onChange, ...other }) => {
    return (
        <TextField
            {...other}
            InputProps={{
                inputComponent: TextMaskCustom as any,
                inputProps: { mask, onChange }
            }}
        />
    );
};

export default MaskedTextField;