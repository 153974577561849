export const CancelButtonStyles:any = {
    props: { variant: 'cancel-button' },
    style: {
        backgroundColor: "#ea3232",
        color: 'white',
        '&:hover': {
            backgroundColor: '#FACCCC',
            color: '#ea3232',
            border: '1px solid #ea3232'
        }
    },
}