import { Dispatch, SetStateAction, useState } from "react";
import { Autocomplete, Chip, IconButton, InputAdornment, TextField } from "@mui/material";
import MessageError from "../MessageError";
import AddIcon from '@mui/icons-material/Add';

interface TwoStepsProps {
    role: any,
    user: any
    setUser: Dispatch<SetStateAction<any>>
    errorsList: any[]
    canalList: any[]
    deleteList: (item:any, type:string) => void
    addList: (type: string, valueInput: string, setValueInput: Dispatch<SetStateAction<string>>) => void
}
const SecondStep = (props: TwoStepsProps) => {
    const { role, user, setUser, errorsList, addList, deleteList, canalList} = props;
    const [valueCanalBuyer, setValueCanalBuyer] = useState<string>("");
    const [valueCanalVendor, setValueCanalVendor] = useState<any>(null);
    const [emailsNotification, setEmailsNotification] = useState<string>("");

    // Metodo que valida si el rol tiene acceso a la ruta
    const validateRoute = (): boolean => {
        let rolesWithRoute = ["admin", "adminsupport", "adminComercial", "displayerSupervisor", "displayer"]
        return rolesWithRoute.includes(role?.id)
    }

    // Metodo que valida si el codigo del usuario
    const validateSLP = (): boolean => {
        let rolesWithSLP = ["userStore"]
        return !rolesWithSLP.includes(role?.id)
    }

    // Metood que valida si el rol puede tener canales de compra
    const validateBuyer = (): boolean => {
        let rolesWithBuyer = ["adminsupport", "buyer", "adminComercial", "cellarmanager"]
        return rolesWithBuyer.includes(role?.id)
    }

    // Metodo que valida si el usuario puede facturar en SAP
    const userNameSAP = (): boolean => {
        let rolesWithBuyer = ["adminsupport", "adminComercial", "userStore"]
        return rolesWithBuyer.includes(role?.id)
    }

    return (
        <>
            <h5>Configuracion de la cuenta Premier Tools</h5>
            <div className="form-second-user">
                {
                    role?.id !== "displayer" &&
                    <>
                        {
                            validateSLP() &&
                            <>
                                <p>Codigo de persona</p>
                                <TextField
                                    name="slp"
                                    helperText={<MessageError message={errorsList?.find(item => item.id === "slp")?.message ?? ""} />}
                                    value={user?.slp ?? ""}
                                    onChange={(e) => setUser((prev: any) => ({ ...prev, slp: e.target.value }))} />
                            </>
                        }
                        <p>Codigos de canales venta</p>
                        <Autocomplete 
                            value={valueCanalVendor}
                            onChange={(_, newValue) => {
                                if (newValue !== null ) addList("vendorCode", newValue.id, setValueCanalVendor)
                            }}
                            renderInput={({ inputProps, ...rest }) => (
                                <TextField
                                    {...rest}
                                    inputProps={{ ...inputProps, readOnly: true }} />
                            )}
                            options={canalList} />
                        <div></div>
                        <div>
                            {
                                user?.vendorCode?.map((item:any, index:number) => (
                                        <Chip key={index} label={item} onDelete={() =>deleteList(item, "vendorCode")} />
                                ))
                            }
                        </div>
                        {
                            validateBuyer() &&
                            <>
                                <p>Codigos de canales compra</p>
                                <TextField
                                    name="buyerCode"
                                    value={valueCanalBuyer}
                                    onChange={(e) => setValueCanalBuyer(e.target.value)}
                                    helperText={<MessageError message={errorsList?.find(item => item.id === "buyerCode")?.message ?? ""} />}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton onClick={() =>{
                                                        addList("buyerCode", valueCanalBuyer, setValueCanalBuyer)
                                                    }}>
                                                    <AddIcon />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }} />
                                <div></div>
                                <div>
                                    {
                                        user?.buyerCode?.map((item: any, index: number) => (
                                            <Chip key={index} label={item} onDelete={() =>deleteList(item, "buyerCode")} />
                                        ))
                                    }
                                </div>
                            </>
                        }
                        <p>Correos de notificacion</p>
                        <div>
                            <TextField 
                                name="emailsNotification"
                                fullWidth
                                value={emailsNotification}
                                onChange={(e) => setEmailsNotification(e.target.value)}
                                helperText={<MessageError message={errorsList?.find(item => item.id === "emailsNotification")?.message ?? ""} />}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <IconButton onClick={() => addList("emailsNotification", emailsNotification, setEmailsNotification)}>
                                                <AddIcon />
                                            </IconButton>
                                        </InputAdornment>
                                    ),
                                }} />
                                <span style={{ fontSize:"0.8rem", color:"#363636"}}>
                                Ten en cuenta que si seleccionaste vendedor, estos ya tienen una lisa de correos por defecto. Podes agregar mas y no afectara a esa lista
                            </span>
                        </div>
                        <div></div>
                        <div>
                            {
                                user?.emailsNotification?.map((item: any, index: number) => (
                                    <Chip key={index} label={item} onDelete={() =>deleteList(item, "emailsNotification")} />
                                ))
                            }
                        </div>
                        {
                            userNameSAP() &&
                            <>
                                <p>Nombre de usuario para facturar</p>
                                <TextField
                                    name="nameSAP"
                                    helperText={<MessageError message={errorsList?.find(item => item.id === "nameSAP")?.message ?? ""} />}
                                    value={user?.nameSAP ?? ""}
                                    onChange={(e) => setUser((prev: any) => ({ ...prev, nameSAP: e.target.value }))} />

                                <p>Codigo de bodega</p>
                                <TextField
                                    name="codeCellar"
                                    helperText={<MessageError message={errorsList?.find(item => item.id === "codeCellar")?.message ?? ""} />}
                                    value={user?.codeCellar ?? ""}
                                    onChange={(e) => setUser((prev: any) => ({ ...prev, codeCellar: e.target.value?.toUpperCase() }))} />
                            </>
                        }
                    </>
                }
            </div>
            <div className="form-fourt-user">
                <p>Lista de precios</p>
                <TextField
                    name="priceList"
                    helperText={<MessageError message={errorsList?.find(item => item.id === "priceList")?.message ?? ""} />}
                    value={user?.priceList ?? ""}
                    onChange={(e) => setUser((prev: any) => ({ ...prev, priceList: e.target.value }))} />
                {
                    validateRoute() &&
                    <>
                        <p>Ruta de salas</p>
                        <TextField
                            name="router"
                            helperText={<MessageError message={errorsList?.find(item => item.id === "router")?.message ?? ""} />}
                            value={user?.router ?? ""}
                            onChange={(e) => setUser((prev: any) => ({ ...prev, router: e.target.value }))} />
                    </>
                }
            </div>
        </>
    )
}

export default SecondStep