export const BackButtonStyles: any = {
    props: { variant: 'back-button' },
    style: {
        color: '#173456',
        minWidth: '110px',
        maxWidth: '200px',
        height: '40px',
        '&:hover': {
            backgroundColor: '#17345610',
        },
    },
}