/* eslint-disable import/first */
import { Suspense, lazy, useEffect, useState } from 'react';
import 'Styles/App.scss';
import {
    BrowserRouter,
    Routes,
    Route
} from "react-router-dom";
import { translate } from "Services/i18n-translate";
import InformationRequest from 'Pages/Comercial/InformationRequest';
import { Box, CircularProgress, ThemeProvider, Typography } from '@mui/material';
import Administration from 'Pages/Home/Admistration';
import UserControl from 'Pages/Administration/UserControl';

import ThemeLight from 'Styles/light-them';
//Paginas
const Login = lazy(() => import("Pages/Login"))
const Index = lazy(() => import("Pages/Index/Index"))
const NotAccessible = lazy(() => import("Pages/NotAccesible"))
const Loader = lazy(() => import("Components/Loader"))

//Home
const Home = lazy(() => import("Pages/Index/Home"))
const Comercial = lazy(() => import("Pages/Home/Comercial"))
const Ecommerce = lazy(() => import("Pages/Ecommerce/Ecommerce"))

//Ecomerce
const GenerateBills = lazy(() => import("Pages/Ecommerce/CreateBill/GenerateBills"))
const ConfirmationBill = lazy(() => import("Pages/Ecommerce/CreateBill/ConfirmationBill"))
const ListBills = lazy(() => import("Pages/Ecommerce/ViewBills/ListBills"))
const About = lazy(() => import("Pages/Index/About"))
const NotFound = lazy(() => import("Pages/NotFound"))
const Notification = lazy(() => import("Pages/Index/Notification"))
const Logistics = lazy(() => import("Pages/Home/Logistics"))
const ClientInformation = lazy(() => import("Pages/Comercial/ClientInformation"))
const DetailsClient = lazy(() => import("Pages/Comercial/DetailsClient"))
const ReportActivity = lazy(() => import("Pages/Comercial/ReportActivity"))
const SalesReport = lazy(()=> import("Pages/Comercial/SalesReport"))
const NewOrder = lazy(()=> import("Pages/Comercial/NewOrder"))
const TechnicalSupport = lazy(()=> import("Pages/Comercial/TechnicalSupport"))

// Vistas para soporte tecnico
// const OrdersSupport = lazy(()=> import("Components/SupportViews/OrdersSupport"))
// const UsersSupport = lazy(()=> import("Components/SupportViews/UsersSupport"))
// const BenchmarksSupport = lazy(()=> import("Components/SupportViews/BenchmarksSupport"))
// const RouterSupport = lazy(()=> import("Components/SupportViews/RouterSupport"))

interface PropsInterface {
    children?: React.ReactNode
}

const LoaderRender = () => {
    const [progress, setProgress] = useState(10);

    useEffect(() => {
        const timer = setInterval(() => {
            setProgress((prevProgress) => (prevProgress >= 100 ? 0 : prevProgress + 10));
        }, 800);
        return () => clearInterval(timer);
    }, []);
    return (
        <div className='loader-app'>
            <Box sx={{ position: 'relative', display: 'inline-flex'}}>
                <CircularProgress value={progress} variant="determinate" sx={{  color:"#112A56" }} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography
                        variant="caption"
                        component="div"
                        color="text.secondary"
                    >{`${Math.round(progress)}%`}</Typography>
                </Box>
            </Box>
        </div>
    )
}

const RenderLazy = (props: PropsInterface) => {
    const { children } = props
    return (
        <Suspense fallback={<LoaderRender/>}>
            {children}
        </Suspense>
    )
}

const App = () => {
    translate('es')
    return (
        <ThemeProvider theme={ThemeLight}>
            <BrowserRouter>
                <Routes>
                    <Route path="login" element={<RenderLazy children={<Login />} />} />
                    <Route path="" element={<RenderLazy children={<Index />} />}>
                        <Route path="" element={<RenderLazy children={
                            <Loader openLoader={true} inPage={true} redirect={true} redirectHome={true} quantity={0} />} />
                        } />
                        <Route path='home'>
                            <Route path="" element={<RenderLazy children={<Home />} />} />
                            <Route path="comercial">
                                <Route path="" element={<RenderLazy children={<Comercial />} />} />
                                <Route path="clientinformation">
                                    <Route path="" element={<RenderLazy children={<ClientInformation />} />} />
                                    <Route path=":id" element={<RenderLazy children={<DetailsClient />} />} />
                                </Route>
                                <Route path="activityrecords" element={<RenderLazy children={<ReportActivity />} />} />
                                <Route path='informationrequest' element={<RenderLazy children={<InformationRequest />} />} />
                                <Route path='salesreports' element={<RenderLazy children={<SalesReport/>}/>}/>
                                <Route path='neworder' element={<RenderLazy children={<NewOrder/>}/>}/>
                                <Route path='support'  element={<RenderLazy children={<TechnicalSupport/>}/>}>
                                    {/* <Route path='orders' element={<RenderLazy children={<OrdersSupport/>}/>} />
                                    <Route path='users' element={<RenderLazy children={<UsersSupport/>}/>} />
                                    <Route path='benchmarks' element={<RenderLazy children={<BenchmarksSupport/>}/>} />
                                    <Route path='routers' element={<RenderLazy children={<RouterSupport/>}/>} /> */}
                                </Route>
                            </Route>
                            <Route path='logistic'>
                                <Route path='' element={<RenderLazy children={<Logistics />} />} />
                            </Route>
                            <Route path="ecommerce">
                                <Route path="" element={<RenderLazy children={<Ecommerce />} />} />
                                <Route path="generatebills">
                                    <Route path="" element={<RenderLazy children={<GenerateBills />} />} />
                                    <Route path="confirmation" element={<RenderLazy children={<ConfirmationBill />} />} />
                                </Route>
                                <Route path="bill" element={<RenderLazy children={<ListBills />} />} />
                            </Route>
                            <Route path='administracion'>
                                <Route path='' element={<RenderLazy children={<Administration />}/>}/>
                                <Route path='usercontrol' element={<RenderLazy children={<UserControl />}/>}/>
                            </Route>
                        </Route>
                        <Route path='notification'>
                            <Route path='' element={<RenderLazy children={<Notification />} />} />
                        </Route>
                        <Route path='about'>
                            <Route path='' element={<RenderLazy children={<About />} />} />
                        </Route>
                        <Route path="*" element={<RenderLazy children={<NotAccessible />} />} />
                    </Route>
                    <Route path="*" element={<RenderLazy children={<NotFound />} />} />
                </Routes>
            </BrowserRouter>
        </ThemeProvider>
    );
}

export default App;
