import React from "react";
import { Bill } from "./Bills";
import { Service } from "./Service";
import { Authentication, User, DialogConfirmation } from "./User";


export interface AssignateBill {
    cardCode:string,
    cardName:string,
    cardFName:string, 
    typeDocument:string, 
    documentNumber:number, 
    typePayment:string, 
    employee:string, 
    dateExpired:string,
    PymntGroup:string,
    extraDays:number, 
    timeExpired:number,
    priceList:number,
    salesPersonCode:number,
    notes:string,
    municipality: string,
    phone: string,
    email: string
    WTLiable: string
    giro: string
    nit: string
    nrc: string
}
//Forma que tendra el contexto del usuario
export interface UserContextModel {
    user: User;
    authenticate:Authentication;
    service:Service
    clientInformation:any;

    //Modelo para generar la factura
    bill : Bill,
    clientList: any[]
    dataCopy : any 

    //Copia de la informacion
    assignateCopyData: (data: any) => void;
    setClient:(data:any)=>void

    //Para ventanas emergentes
    dialogConfirmation: DialogConfirmation
    assignateBill: ( data:AssignateBill ) =>void;
    assiganteProdcutsBills: (products : any[]) => void;
    assignateDetailBill:(data:any)=> void

    assignateExcentIVA:(data:boolean) => void

    assignateDateDocument: (data:string)=>void;
    assignateDateConta: (data:string)=> void;
    assignateDateExpired: (data:number)=>void;
    // assignateSocio: (cardCode:string, cardFName:string, u_type:string, giro:string, priceList:number, nit:string, nrc:string) => void,

    //Asigna los valores a la ventana emergente
    assignateOpen:(open:boolean)=> void;
    assignateAnswer:(open:boolean)=> void;

    //Asigna la lista de clientes
    assignateClientList : (data:any[] )=> void,

    assignateAuth :(id:any, authenticate:boolean)=>void;
    loginUser:(email: string, password:string)=> void ;
    assignUser: (data:User)=> void;
    assignateService: (createAccountComercial:boolean)=> void;
    assignateRouter : (router:any) =>void;
}


//Creacion del contexto
const UserContextP = React.createContext<UserContextModel>({
    user: { 
        id:"", 
        name:"", 
        lastName:"", 
        vendorCode:"", 
        email:"", 
        salesPersonCode:0, 
        priceList:0, 
        branchOffice:0,
        role: "",
        codeCellar: "",
        phone:"",
        creationDate:"",
        lastLogin:"",
        documentUser:"",
        device: "",
        vendorName: "",
        buyerCode: ""
    },
    authenticate :{uid:'', auth:false},
    service : { createAccountComercial: false, router:{} },
    clientList: [],

    dataCopy:{},

    //Informacion de la factura
    bill: {   
        cardCode:"",
        cardFName:"",
        cardName:"", 
        u_type:"",
        email :"",
        phoneNumber: "",
        municipality: "",
        salesPersonCode:0,
        giro:"",
        priceList:0,
        exentIVA: false,
        nit:"",
        nrc:"",
        typePayment:"",
        extraDays:0,
        PymntGroup:"",
        typeDocument:"", 
        documentNumber:0, 
        documentDate:"",
        documentDateConta:"", 
        documentDateExpiration: "",
        nameEmployee:"",  
        products:[], 
        dateExpired:"", 
        timeExpired:0,
        detailBill:{},
        notes:"",
        WTLiable: ""
    },
    clientInformation: {},


    //Ventanas emergentes
    dialogConfirmation:{open: false, answer:false},
    assignateOpen: ()=>{},
    assignateAnswer: ()=>{},
    
    //Copia de la informacion
    assignateCopyData: ()=>{},
    assignateExcentIVA: ()=>{},
    setClient: ()=>{},

    //Metodos para la factura
    assignateBill :()=>{},
    assiganteProdcutsBills: ()=>{},
    assignateDateDocument: ()=>{},
    assignateDateExpired: ()=>{},
    assignateDateConta: ()=>{},
    // assignateSocio: () =>{},
    assignateDetailBill: ()=>{},

    //Asigna la lista de clientes
    assignateClientList: () =>{},


    assignateService: ()=>{}, 
    assignateAuth : ()=>{},
    assignUser : ()=>{},
    assignateRouter : () =>{},
    loginUser: ()=>{},
})

export default UserContextP;