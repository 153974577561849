import { useCallback, useContext, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"

import Loader from "Components/Loader"
import UserContextP from "Interfaces/Context"

// Componentes
import UsersSupport from "Components/SupportViews/UsersSupport"

//Iconos
import AppControlIcon from 'assets/images/Administration/control-app.png'
import AuthorizationIcon from 'assets/images/Administration/order-processed.png'
import RoleControlIcon from 'assets/images/Administration/role-control.png'
import AccountControl from 'assets/images/Administration/id-card.png'

const UserControl = ( ) =>{
    const context = useContext(UserContextP)
    const { t } = useTranslation()
    const [itemSelected, setItemSelected] = useState("")
    
    const elementsValidateRender = useCallback((permiss: string[]): any[] => {
        let list = [
            {
                title: t("userControl.text_1"),
                url: "users",
                type: "userReview",
                icon: <img src={AccountControl} className="icon-item" alt='account-management'/>
            },{
                title: t("userControl.text_2"),
                url: "roleGestion",
                type: "roleManagement",
                icon: <img src={RoleControlIcon} className="icon-item" alt='rol-management'/>
            },{
                title: t("userControl.text_3"),
                url: "authorizationOrder",
                type: "orderAuthorization",
                icon: <img src={AuthorizationIcon} className="icon-item" alt='authorizer'/>
            },{
                title: t("userControl.text_4"),
                url: "appControl",
                type: "controlApp",
                icon: <img src={AppControlIcon} className="icon-item" alt='app-management'/>
            }
        ]
        list = list.filter((item: any) => permiss.includes(item.type));
        return list
        //eslint-disable-next-line
    }, [])

    // Listado de elementos
    const List: any[] = useMemo(() => {
        if (context.user.role === "admin" || context.user.role === "adminsupport") {
            setItemSelected("users")
            return elementsValidateRender([
                "userReview",
                "roleManagement",
                "orderAuthorization",
            ])
        } else if (  context.user.role === "adminComercial") {
            setItemSelected("users")
            return elementsValidateRender([
                "userReview",
            ])
        } else if (context.user.role === "displayerSupervisor") {
            setItemSelected("users")
            return elementsValidateRender([
                "userReview",
                "roleManagement",
                "orderAuthorization",
                "controlApp"
            ])
        }
        return []
    }, [context.user.role, elementsValidateRender])

    return (
        <>
            {
                context.user.role !== "" ? 
                    <>
                        <div className="container-tecnhical-supoprt">
                            <div className="menu-options">
                                <div className="content-options">
                                    {
                                        List?.map((item: any, i: number) => {
                                            return (
                                                <div
                                                    key={i}
                                                    className={"item-option-menu " + (itemSelected === item.url && "item-selected")}
                                                    onClick={() => setItemSelected(item.url)}>
                                                    {item.icon}
                                                    <p>{item.title}</p>
                                                </div>
                                            )
                                        })
                                    }
                                </div>
                            </div>
                            <div className="content-technical">
                                { itemSelected === "users" && <UsersSupport /> }
                            </div>
                        </div>
                    </>
                    :
                    <Loader
                        inPage={true}
                        openLoader={true}
                        redirect={false}
                        redirectHome={false}
                        quantity={0}/>
            }
        </>
    )
}

export default UserControl;
