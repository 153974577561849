import {
    Grid,
    Divider,
    Checkbox,
} from "@mui/material";

import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HappyBox from "assets/images/Comercial/GenerateOrder/HappyBox.png"

interface PropsInterface {
    item: any,
    productSelected: (data: any) => void,
    productCopy?: any
    showCheckbox: boolean
    showDetails: boolean
    showPrice: boolean
}

const ItemOrder = (props: PropsInterface) => {
    const { item, productCopy, showCheckbox } = props;
    return (
        <div style={{ marginTop: "20px" }} onClick={() => props.productSelected(item)}>
            <Grid container spacing={1} sx={{ marginBottom: "5px", height:"150px" }}>
                {
                    showCheckbox &&
                        <Grid item xs={2}>
                            <Checkbox
                                icon={<CheckCircleOutlineIcon />}
                                onClick={() => props.productSelected(item)}
                                checkedIcon={<CheckCircleIcon sx={{ color: "#173456" }} />}
                                checked={productCopy?.includes(item?.id)}
                            />
                        </Grid>
                }

                <Grid item xs={2} sx={{ margin:"auto" }} alignContent="center" alignItems="center">
                    <img src={HappyBox} alt="Generar un pediddo" style={{ width:"60%", aspectRatio:"0.999/1", margin:"auto" }}  />
                </Grid>
                <Grid item xs={ showCheckbox ? 6 : props.showPrice ?  8 : 10} sx={{ marginTop: "-10px" }}>
                    <p style={{ fontSize: "80%" }}>{item?.ItemName}</p>
                    <p style={{ fontSize: "60%", marginTop: "-10px" }}>{item?.id}</p>
                    {
                        props.showDetails &&
                        <>
                            <p style={{ fontSize: "80%", marginTop: "-10px" }}>Inventario en bodega</p>
                            {item?.onHandDetails?.map((iteminventory: any, indexElement: number) => {
                                return (
                                    <p key={indexElement} style={{ fontSize: "80%", marginTop: "-10px" }}>{iteminventory}</p>
                                )
                            })}
                        </>
                    }
  
                </Grid>
                {
                    props.showPrice &&
                    <Grid item xs={2}>
                        <p style={{ fontSize: "60%" }}>${parseFloat(item?.price?.toString()).toFixed(2)}</p>
                    </Grid> 
                }
            </Grid>
            <Divider variant="middle" sx={{ height: "0.5px", backgroundColor: "#30B595" }} />
        </div>
    )
}

export default ItemOrder;