import {
    Card,
    CardContent,
    CardActionArea
} from '@mui/material';
import { useNavigate } from 'react-router';

const Cards = (props: any) => {
    const navigate = useNavigate()

    //Navegacion entre componentes
    const navigateTo = () => navigate(`${props.item.path}`)

    return (
        <div  className='cards-root'>
            {props.site === "home" &&
                <Card className='card-base card-home'  onClick={navigateTo} >
                    <CardActionArea>
                        <img 
                            src={props.item.img} 
                            alt={props.item.description}  />
                        <CardContent className="cardContent" style={{position:'absolute'}}>
                            <h4 className="baseH4">{props.item.title}</h4> :
                        </CardContent>
                    </CardActionArea>
                </Card>
            }
            {props.site === "comercial" &&
                <Card  className='card-base card-comercial' sx={{minWidth: '100px', minHeight: '15vh', maxHeight:'16vh' }} onClick={navigateTo} >
                    <CardActionArea>
                        <div>
                            {
                                props.item.description !== "delivered" ? 
                                <img 
                                    src={props.item.img} 
                                    alt={props.item.description}  />
                                : 
                                <img src={ props.item.img } alt = {props.item.description} style={{ display:"block", margin:"auto", aspectRatio:"1/1", width:"60%", marginTop:"7px"}}/>
                        }
                        </div>
                        {
                            props.item.description !== "delivered" ? 
                            <CardContent className="cardContentComercial" style={{ marginTop:"1px", width:"100%", alignItems:"center", justifyItems:"center"}}>
                                <h4 className="baseH4Comercial">{props.item.title}</h4>
                            </CardContent>
                        :
                            <CardContent className="cardContentComercial" style={{ marginTop:"-19px", width:"100%"}}>
                                    <h4 className="baseH4Comercial">{props.item.title}</h4>
                                </CardContent>
                        }
                    </CardActionArea>
                </Card>
            }
        </div>
    )
}

export default Cards;