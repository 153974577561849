import { useCallback, useContext, useMemo } from "react"
import { useTranslation } from "react-i18next"

// Interfaces
import UserContextP from "Interfaces/Context"
import { CardItem } from "Interfaces/Cards"

// Iconos
import UserAdministration from "assets/images/Administration/user-control.png"
import Cards from "Components/Cards"
import { Slide } from "@mui/material"

const Administration = () => {
    const context = useContext(UserContextP)
    const { t } = useTranslation()

    const elementsValidateRender = useCallback((permiss: string[]): CardItem[] => {
        let cards = [
            {
                id: 0,
                title: t('userControl.text_1'),
                path: "usercontrol",
                img: UserAdministration,
                description: "register",
                type: "userAdministration"
            }
        ]
        cards = cards.filter((item: CardItem) => permiss.includes(item.type));
        return cards
    }, [t])

    const cardsInfo: CardItem[] = useMemo(() => {
        if (context.user.role === "admin" || context.user.role === "adminComercial") {
            return elementsValidateRender([
                "userAdministration"
            ])
        } else if (context.user.role === "adminsupport") {
            return elementsValidateRender([
                "userAdministration"
            ])
        }
        return []
    }, [context.user.role, elementsValidateRender])

    return( 
        <Slide direction="left" in={true}>
            <div style={{ display: 'block', margin: '20px auto', width: "95%" }}>
                {
                    cardsInfo.length > 0 ?
                        <div className='gridContainerComercial'>
                            { cardsInfo.map((item, index) => <Cards key={index} item={item} site={"comercial"} />) }
                        </div>
                        :
                        <div style={{ textAlign: "center", marginTop: "30%" }}>
                            <p>No tienes permisos para acceder a este modulo</p>
                        </div>
                }
            </div>
        </Slide>
    )
}

export default Administration