import { useEffect, useContext } from "react";
import {
    Backdrop,
    CircularProgress
} from '@mui/material';

import 'Styles/Loader.scss'

import { useNavigate } from "react-router";
import UserContext from 'Interfaces/Context';

//Metodos de servicios
import { getDataInitialUser } from "Services/Authentication";
import { getDocument } from "Services/Firebase";
import { auth } from "Services/Authentication"

interface LoaderProps {
    openLoader: boolean;
    inPage: boolean;
    redirect: boolean;
    redirectHome: boolean 
    quantity: number
}

const Loader = (props: LoaderProps) => {
    const navigate = useNavigate()
    const context = useContext(UserContext);

    //Funcion de carga
    useEffect(() => {
        if ( props.redirect && props.redirectHome ) {
            auth.onAuthStateChanged(async (firebaseUser: any) => {
                if (firebaseUser === null) {
                    navigate('/login')
                    context.assignateAuth('', false)
                } else {
                    await getDataInitial()
                    context.assignateAuth(firebaseUser?.uid, true)
                    let user: any = await getDataInitialUser(firebaseUser?.uid, context.service.router.POST.get_user_information)
                    context.assignUser({
                        id: user.id ?? "",
                        lastName: user.last_name ?? "",
                        name: user.name ?? "",
                        email: user.email ?? "",
                        vendorCode: user.vendorCode ?? "",
                        salesPersonCode: user.salesPersonCode ?? "",
                        priceList: user.priceList ?? 0,
                        branchOffice: user.branchOffice ?? 0,
                        role: user.role ?? "",
                        codeCellar: user.codeCellar ?? "",
                        documentUser: user.documentUser ?? "",
                        phone: user.phone ?? "",
                        creationDate: user.creationDate ?? "",
                        lastLogin: user.lastLogin ?? "",
                        device: user.device ?? "",
                        vendorName: user.vendorName ?? "",
                        buyerCode: user.buyerCode ?? ""
                    })
                    if (props.redirect) {
                        navigate('/home')
                    }
                }
            });
        }

        // eslint-disable-next-line
    }, [])

    //Trae la informacion inicial
    const getDataInitial = async () => {
        auth.onAuthStateChanged(async (firebaseUser: any) => {
            context.assignateAuth(firebaseUser?.uid, true)
        });
        let element = await getDocument("configurations", `${ process.env.REACT_APP_CONFIGURATION_REQUEST }`)
        context.assignateRouter(element)
        navigate('/home')

    }

    return (
        <div>
            {
                props.inPage ?
                    <div className="loaderContent">
                        <CircularProgress color="inherit" />
                    </div>
                    :
                    props.redirect ?
                        <Backdrop
                            sx={{ color: '#ffffff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={props.openLoader}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                        :
                        <Backdrop
                            sx={{ color: '#000', backgroundColor: '#ffffff' }}
                            open={props.openLoader}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>

            }
        </div>
    )
}

export default Loader;