import { useState, useEffect } from "react"

const UseDebonce = (val:any, delay:number) =>{
    const [debounceVal, setDebounce ] = useState(val)

    useEffect(()=>{
        const handler = setTimeout(()=>{
            setDebounce(val)
        }, delay)

        return () => {
            clearTimeout(handler)
        }
    // eslint-disable-next-line
    }, [val])

    return debounceVal;
}

export default UseDebonce