export const SubmitButtonStyles: any = {
    props: { variant: 'submit-button' },
    style: {
        backgroundColor: "#173456",
        color: 'white',
        '&:hover': {
            backgroundColor: '#17345610',
            color: '#173456',
            boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
            border: '1px solid #173456'
        },
    },
}